import { logger } from 'client/utils/isomorphic-logger';
import { getCookie } from './get-cookie';
import { setCookie } from './set-cookie';
import { TIME } from './time';
import experimentCookie from './experiments-config.json';

/**
 * Loads settings from config file.
 *
 * @returns {Object}  An object with fields base, baseSet, eDigits, rDigits and maxExposureRecordLength
 */
const loadBaseSettings = () => {
  const { base, letters, eDigits, rDigits, maxTuplesCount, cookieName } = experimentCookie;
  const baseSet = [];
  const maxExposureRecordLength = maxTuplesCount * (eDigits + rDigits);
  baseSet[base] = letters;

  return { base, baseSet, eDigits, rDigits, maxExposureRecordLength, cookieName };
};

const { base, baseSet, eDigits, rDigits, maxExposureRecordLength, cookieName } = loadBaseSettings();

/**
 * Converts given number to its string representation
 * using base and a set of symbols for base and using
 * only given number of digits.
 *
 * @param {Number} num      Number to convert
 * @param {Number} digits   Number of digits or basically the length of result string
 * @returns {String}        String representation of a number
 */
const intToBaseString = (num, digits) => {
  if (num >= base ** digits) {
    throw new Error(`Encoded number takes more than ${digits} digits`);
  }
  let result = '';
  let i = num;

  for (let p = digits - 1; p >= 0; p -= 1) {
    const bpv = base ** p;
    const pv = Math.floor(i / bpv);

    i -= pv * bpv;
    result += baseSet[base][pv];
  }

  return result;
};

/**
 * Converts given string to a number using base and
 * a set of symbols for base.
 *
 * @param {String} str  String representation of a number
 * @returns {Number}    An integer number
 */
const baseStringToInt = str =>
  str
    .split('')
    .reverse()
    .reduce((prev, curr, index) => prev + baseSet[base].indexOf(curr) * base ** index, 0);

/**
 * Maps experiment and its recipe names to ids.
 *
 * @param {Array} experimentTuple          An array with 2 items: experiment name and recipe name
 * @param {Object} experimentsData         An object (each field contains an object with fields for an experiment)
 * @returns {Array[Number]}                An array with 2 items: experiment id and a recipe id
 */
const getIdsFromExperimentData = (experimentTuple, experimentsData) => {
  const [experimentName, recipeName] = experimentTuple;
  const experimentRecord = experimentsData[experimentName];
  if (!experimentRecord) return undefined;

  const recipeIndex = experimentRecord.recipeNames?.indexOf(recipeName);
  const recipeId = experimentRecord.recipeIds?.[recipeIndex];

  if (recipeId !== undefined) return [experimentRecord.id, recipeId];
  logger(
    'error',
    `Recipe record with name ${recipeName} from campaign with id ${experimentRecord.id} not found in experiment data`
  );
  return undefined;
};

/**
 * Encodes the array of experiments and recipes names into the exposure record with a header.
 *
 * @param {Object} experimentsData            An object (each field contains an object with fields for an experiment)
 * @param {Array[Array]} experimentNames      An array of arrays with 2 items: experiment name and recipe name
 * @returns {String}                          An exposure record with a header
 */
const encodeExposureRecord = (experimentsData, experimentNames) => {
  const experimentsIds = experimentNames.reduce((resultArray, experimentTuple) => {
    const experimentRecord =
      typeof experimentTuple[0] === 'number' && typeof experimentTuple[1] === 'number'
        ? experimentTuple
        : getIdsFromExperimentData(experimentTuple, experimentsData);
    if (experimentRecord) resultArray.push(experimentRecord);
    return resultArray;
  }, []);
  const experimentsString = experimentsIds
    .map(record => {
      const campaignRecord = intToBaseString(record[0], eDigits);
      const recipeRecord = intToBaseString(record[1], rDigits);

      return `${campaignRecord}${recipeRecord}`;
    })
    .join('');
  const header = eDigits.toString() + rDigits.toString();

  return `${header}${experimentsString}`;
};

/**
 * Decodes the exposure record and returns an array of arrays with experiments names and recipes names.
 *
 * @param {String} exposureRecord   An exposure record with a header
 * @param {Object} experimentsData  An object (each field contains an object with fields for an experiment)
 * @param {Object}
 *  {Boolean} keepNotFoundExperimentId If experiment not found in experiments data then save experiment and recipes ids instead of names
 *  {Boolean} keepNotFoundRecipeId     If recipe not found in experiments data then save recipe id instead of name
 * @returns {Array[Array]}          An array of arrays with 2 items: experiment name and recipe name
 */
const decodeExposureRecord = (
  exposureRecord,
  experimentsData,
  { keepNotFoundExperimentId = false, keepNotFoundRecipeId = false } = {}
) => {
  if (exposureRecord === '' || exposureRecord === undefined) return [];
  const recEdigits = Number(exposureRecord[0]);
  const recRdigits = Number(exposureRecord[1]);
  const campaignsAndRecipes = exposureRecord.slice(2);
  const tupleLength = recEdigits + recRdigits;
  const records = [];

  for (let i = 0; i < campaignsAndRecipes.length; i += tupleLength) {
    records.push(campaignsAndRecipes.slice(i, i + tupleLength));
  }

  return records.reduce((resultArray, currentRecord) => {
    if (currentRecord.length !== tupleLength) {
      logger('error', `Unexpected extra information ${currentRecord} in exposure record ${exposureRecord}`);

      return resultArray;
    }

    const experimentCode = currentRecord.slice(0, recEdigits);
    const recipeCode = currentRecord.slice(recEdigits, tupleLength);
    const experimentId = baseStringToInt(experimentCode);
    const recipeId = baseStringToInt(recipeCode);
    const experimentRecord = Object.values(experimentsData).find(experiment => experiment.id === experimentId);

    if (!experimentRecord && !keepNotFoundExperimentId) return resultArray;
    if (!experimentRecord && keepNotFoundExperimentId) {
      resultArray.push([experimentId, recipeId]);
      return resultArray;
    }

    const experimentName = experimentRecord.name;
    const recipeIndex = experimentRecord.recipeIds?.indexOf(recipeId);
    const recipeName = experimentRecord.recipeNames?.[recipeIndex];

    if (recipeName) {
      resultArray.push([experimentName, recipeName]);
      return resultArray;
    }
    if (keepNotFoundRecipeId) resultArray.push([experimentName, recipeId]);

    logger(
      'error',
      `Recipe record with id ${recipeId} from campaign with name ${experimentName} not found in experiment data`
    );

    return resultArray;
  }, []);
};

/**
 * Merges two exposure records together and returns the result string as an exposure record with a header.
 *
 * @param firstRecord an exposure record with a header
 * @param secondRecord an exposure record with a header
 * @returns {string} an exposure record with a header
 */
const mergeExposureRecords = (firstRecord = '', secondRecord = '') => {
  const header = `${eDigits.toString()}${rDigits.toString()}`;
  const tupleLength = Number(header[0]) + Number(header[1]);
  const bothRecords = `${firstRecord.slice(2)}${secondRecord.slice(2)}`;
  const records = [];

  for (let i = 0; i < bothRecords.length; i += tupleLength) {
    records.push(bothRecords.slice(i, i + tupleLength));
  }

  const exposureRecordBody = Array.from(new Set(records)).join('');

  return exposureRecordBody === '' ? '' : `${header}${exposureRecordBody}`;
};

/**
 * Decodes the exposure record and returns a Map of experiment name to recipe name.
 *
 * @param {String} exposureRecord   An exposure record with a header
 * @param {Object} experimentsData  An object (each field contains an object with fields for an experiment)
 * @param {Object}
 * {Boolean} keepNotFoundExperimentId If experiment not found in experiments data then save experiment and recipes ids instead of names
 * {Boolean} keepNotFoundRecipeId     If recipe not found in experiments data then save recipe id instead of name
 * @returns {Map[string,string]}    A Map with keys experiment names and values recipe names
 */
const decodeToMap = (
  exposureRecord,
  experimentsData,
  { keepNotFoundExperimentId = false, keepNotFoundRecipeId = false } = {}
) => new Map(decodeExposureRecord(exposureRecord, experimentsData, { keepNotFoundExperimentId, keepNotFoundRecipeId }));

/**
 * Inserts a single ExperimentName, RecipeName tuple into the exposure record.
 *
 * @param {String} experimentName   experiment name
 * @param {String} recipeName       recipe name
 * @param {Object} experimentsData  An object (each field contains an object with fields for an experiment)
 * @param {String} exposureRecord   An exposure record with a header
 * @returns {String}                An exposure record with a header
 */
const insertExperimentTuple = (experimentName, recipeName, experimentsData, exposureRecord) => {
  const experimentNames = decodeExposureRecord(exposureRecord, experimentsData, { keepNotFoundExperimentId: true });
  const singleExperimentData = experimentsData[experimentName];
  if (!singleExperimentData) {
    logger('error', 'insertExperimentTuple: the experiment was not found! Details: ');
    logger(
      'error',
      JSON.stringify({
        singleExperimentData,
        experimentName,
        recipeName,
        exposureRecord,
      })
    );
  }

  const experimentIndex = experimentNames.findIndex(record =>
    typeof record[0] === 'number' ? record[0] === singleExperimentData?.id : record[0] === experimentName
  );

  const experimentTuple = [experimentName, recipeName];
  // check if recipe id exists in experimentsData
  if (!getIdsFromExperimentData(experimentTuple, experimentsData)) {
    return exposureRecord;
  }

  let result = [experimentTuple, ...experimentNames];
  if (experimentIndex >= 0) {
    // pop it from current position in exposure record and insert in the first position
    result = [
      experimentTuple,
      ...experimentNames.slice(0, experimentIndex),
      ...experimentNames.slice(experimentIndex + 1),
    ];
  }

  result = encodeExposureRecord(experimentsData, result);
  return result.slice(0, maxExposureRecordLength);
};

/**
 * Sets the cookie with predefined options
 *
 * @param {String} exposureRecord   An exposure record with a header
 */
const setExposureRecordCookie = exposureRecord => {
  const resultCookie = setCookie(cookieName, exposureRecord, {
    domain: '.edmunds.com',
    httpOnly: false,
    secure: true,
    maxAge: TIME.MS.TEN_YEARS,
    encode: val => val,
    path: '/',
  });

  document.cookie = resultCookie;
};

const getExposureRecordCookie = req => req?.cookies?.[cookieName] ?? '';

const getExposureRecordCookieOnClient = () => getCookie(cookieName);

export {
  setExposureRecordCookie,
  decodeExposureRecord,
  decodeToMap,
  encodeExposureRecord,
  insertExperimentTuple,
  getExposureRecordCookie,
  getExposureRecordCookieOnClient,
  mergeExposureRecords,
};
