import PropTypes from 'prop-types';
import { isEmpty, get, pickBy, identity, omitBy, isNil } from 'lodash';
import uuidv5 from 'uuid/v5';
import uuidv4 from 'uuid/v4';
import { EdmundsAPI, EdmundsPartnerOfferAPI } from 'client/data/api/api-client';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import {
  PARTNER_OFFER_ADDRESS_LOOKUP,
  EPO_UUIDV5_NAMESPACE,
  PARTNER_OFFER_APPOINTMENT_DRAWER_CREATIVE_ID,
} from 'site-modules/shared/constants/appraisal/appraisal';
import { PARTNERS } from 'site-modules/shared/constants/multi-offer/partners';
import { getSquishVIN } from 'site-modules/shared/utils/vin-utils';
import { withMetrics } from 'client/data/api/api-metrics';
import { fillInPartner, transformQuestions } from 'client/data/transforms/appraisal/transform-questions';
import { getQuery } from 'client/utils/location';
import { getSyncDate } from 'site-modules/shared/components/profile/idm/idm-utils';
import { getDifferenceInDays } from 'site-modules/shared/utils/date-utils';

import { VehicleModel, buildUsedTmvPricingWithOptionsPath, buildOptionWithOemCodeStylePath } from './vehicle';
import {
  VehicleVinModel,
  buildStylesBasicPathFromSquishVin,
  buildStylesBasicPathFromVin,
  VehicleVinEntities,
} from './vehicle-vin';
import { MultiOfferModel, searchByVinPath, modsRecordPath } from './multi-offer';
import { PageModel } from './page';

export const VENOM_X_PRODUCT_ID_HEADER = { 'X-Product-Id': 'venom' };

export const SMS_MESSAGE_TYPE = {
  EMO_1667: 'EMO-1667',
};

export const getVehicleInfoFromModsData = ({
  vehicle,
  kmxConditionQuestions,
  carwiserConditionQuestions,
  offerFinalizedAt,
  seller,
}) => ({
  vin: vehicle.vin,
  styleId: vehicle.edmundsStyleId,
  mileage: vehicle.mileage,
  condition: vehicle.condition,
  transmission: vehicle.transmissionType,
  exteriorColor: vehicle.exteriorColor,
  interiorColor: vehicle.interiorColor,
  selectedOptions: vehicle.selectedOptions,
  conditionQuestionsResponses: {
    kmx: kmxConditionQuestions.map(({ id, answers }) => ({
      id: +id,
      answers: answers.map(({ id: answerId }) => +answerId),
    })),
    // do NOT need to transform the IDs into integers for Carwiser, because they are EXPECTED to be strings.
    carwiser: carwiserConditionQuestions.map(({ id, answers }) => ({
      id,
      answers: answers.map(({ id: answerId }) => answerId),
    })),
  },
  offerFinalizedAt,
  source: 'MODS',
  abTestBucketing: seller.abTestBucketing,
});

export const calculatePrepopulatedMileage = lastQuote => {
  const createdDateUtc = new Date(lastQuote.createdDateUtc);
  const daysPast = getDifferenceInDays(createdDateUtc);
  let currentMileage = lastQuote.mileage;
  if (daysPast >= 30 && daysPast < 60) {
    currentMileage += 1000;
  } else if (daysPast >= 60) {
    currentMileage += 2000;
  }
  return currentMileage;
};

/**
 * Method to make a call to send SMS.
 * @param {String} phoneNumber - The phone number to send SMS.
 * @param {String} url - The offer redemption certificate uri
 */
export const sendOfferSms = ({ phoneNumber, url, location = {}, messageData }) => {
  const messageType = getQuery(location).messageType;
  const fullPayload =
    messageType === 'happy-path-mock'
      ? {
          messageType,
        }
      : {
          messageType: SMS_MESSAGE_TYPE.EMO_1667,
          to: phoneNumber,
          shortUrl: {
            ttl: '7d',
            url,
          },
          messageData,
        };

  return EdmundsPartnerOfferAPI.fetchJson('/sms/messages', {
    headers: { ...VENOM_X_PRODUCT_ID_HEADER, 'content-type': 'application/json' },
    retries: 0,
    method: 'POST',
    body: JSON.stringify(omitBy(fullPayload, isNil)),
  });
};

export const submitAppointment = async payload => {
  let response;

  try {
    response = await EdmundsPartnerOfferAPI.fetchJson(`/v2/appointments`, {
      headers: {
        'content-type': 'application/json',
        ...VENOM_X_PRODUCT_ID_HEADER,
      },
      retries: 1,
      method: 'POST',
      body: JSON.stringify(payload),
    });
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        subaction_name: TrackingConstant.SCHEDULE_APPOINTMENT,
        creative_id: PARTNER_OFFER_APPOINTMENT_DRAWER_CREATIVE_ID,
        value: 'success',
      },
    });
  } catch (e) {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        subaction_name: TrackingConstant.SCHEDULE_APPOINTMENT,
        creative_id: PARTNER_OFFER_APPOINTMENT_DRAWER_CREATIVE_ID,
        value: `${e.status}_ERROR`,
      },
    });
    response = { isError: true };
  }

  return response;
};

export const getLicensePlateErrorState = state => (state.partnerOffer ? state.partnerOffer.licensePlateError : null);

export const getVinsByAddress = async (address, creativeId = PARTNER_OFFER_ADDRESS_LOOKUP) => {
  let response;
  try {
    response = await EdmundsPartnerOfferAPI.fetchJson('/vins/search-by-address', {
      headers: { ...VENOM_X_PRODUCT_ID_HEADER, 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(address),
    });
    const { vehicles = [], message = 'No VINs returned for address' } = response;
    if (!vehicles.length) {
      throw new Error(message);
    }
    response = {
      vehicles,
    };
  } catch (e) {
    response = {
      vehicles: [],
    };
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        creative_id: creativeId,
        value: `${e.status}_ERROR`,
      },
    });
  }

  return response;
};

export const getTmvHistory = async ({ styleId, zip, mileage, monthlyMileage, optionIds, condition, interval }) => {
  let response;

  try {
    response = await EdmundsAPI.fetchJson(
      `/usedtmv/v4/historicaltmvs?${objectToQueryString(
        pickBy(
          {
            styleId,
            zip,
            mileage,
            monthlyMileage,
            optionIds,
            condition,
            interval: interval || 'MONTH',
            intervalCount: 12,
          },
          identity
        )
      )}`
    );
    const { historicalTmv } = response;
    const tmvValues = get(historicalTmv, 'pricesWithAdjustments.adjustedPrices', []);
    const usedTradeInPrice = get(tmvValues, '[0].price.usedTradeIn');
    if (tmvValues.length <= 1 && !usedTradeInPrice) {
      response = { noTmvData: true };
    } else {
      response = historicalTmv;
    }
  } catch (e) {
    response = { noTmvData: true };
  }

  return response;
};

export const PARTNER_OFFER_PARAMETERS_PATH = {
  VIN: 'offerParameters.vin',
};

export function buildLicensePlateStateCodeForVinPath({ licensePlate, stateCode }) {
  return licensePlate && stateCode ? `vin.licensePlate["${licensePlate}"].stateCode["${stateCode}"]` : '';
}

export function buildPartnerOfferSquishStylesValidationPath({ vin, make, model, year, isVinDecoderUsed }) {
  return vin && make && model && year
    ? `vin["${vin}"].make["${make}"].model["${model}"].year["${year}"].${
        isVinDecoderUsed ? 'vinDecoderStyles' : 'squishStyles'
      }`
    : '';
}

export const partnerOfferVehicleInfoByVinPath = {
  build: ({ vin }) => (vin ? `vin["${vin}"].vehicle.info` : ''),
  segment: 'vin["{vin}"].vehicle.info',
};

export const partnerOfferVehicleInfoByModsIdPath = {
  build: ({ modsId }) => (modsId ? `modsId["${modsId}"].vehicle.info` : ''),
  segment: 'modsId["{modsId}"].vehicle.info',
};

export const buildPartnerOfferVehicleInfoPath = ({ vin, modsId }) => {
  if (modsId) {
    return partnerOfferVehicleInfoByModsIdPath.build({ modsId });
  }
  if (vin) {
    return partnerOfferVehicleInfoByVinPath.build({ vin });
  }
  return '';
};

export function buildPartnerOffersConditionsQuestionsPath(location) {
  const isE2E = getQuery(location).e2e;
  return `conditionsQuestions${isE2E ? '.e2e' : ''}`;
}
/**
 * CarMax questions transformed for usage in VAC Step 4.
 */
export const transformedConditionsQuestionsPath = {
  build: () => 'transformedConditionsQuestions',
  segment: 'transformedConditionsQuestions',
};

/**
 *
 * @param {String} offerCode - Required to create new call (and cache) based off of every offer
 * @param {Object} store - CarMax store information to obtain storeId
 * @param {String} startTime - Date string (Local time)
 * @param {String} endTime - Date string (Local time)
 * @returns {String} luckdragon path to get available appointment slots.
 */
export function buildPartnerOffersAppointmentsSlotsPath({ offerCode, store, startTime, endTime }) {
  const storeId = get(store, 'id');
  return offerCode && storeId && startTime && endTime
    ? `offerCode["${offerCode}"].appointments.storeId["${storeId}"].startTime["${startTime}"].endTime["${endTime}"].slots`
    : '';
}

const fireGetAppointmentSlotsTracking = value => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_RECEIVE_EPO,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      subaction_name: TrackingConstant.SUBACTION_EPO_GET_APPOINTMENT_SLOTS,
      creative_id: PARTNER_OFFER_APPOINTMENT_DRAWER_CREATIVE_ID,
      value,
    },
  });
};

const fireReceiveAppointmentSlotsTracking = value => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_RECEIVE_EPO,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      subaction_name: TrackingConstant.SUBACTION_EPO_RECEIVE_APPOINTMENT_SLOTS,
      creative_id: PARTNER_OFFER_APPOINTMENT_DRAWER_CREATIVE_ID,
      value,
    },
  });
};

/**
 * Clears vinEligibility path (by setting it to "undefined") so it can make a new call to obtain a quoteId
 * @param vin
 * @param make
 * @param model
 * @param year
 * @returns {Promise<void>}
 */
export const clearVinEligibilityCache = async ({ context, vin, make, model, year }) => {
  await context.updateValue(buildPartnerOfferSquishStylesValidationPath({ vin, make, model, year }), undefined);
};

export const PartnerOfferModel = createModelSegment('partnerOffer', [
  {
    path: 'vin["{vin}"].make["{make}"].model["{model}"].year["{year}"].squishStyles',
    async resolve({ vin, make, model, year }, context) {
      const squishStylesResponse = await context.resolveValue(
        buildStylesBasicPathFromSquishVin({ squishVin: getSquishVIN(vin) }),
        VehicleVinModel
      );
      const { results: squishStyles, hasError, status } = squishStylesResponse ?? {};
      const isSquishVinValid = squishStyles.length
        ? squishStyles.some(
            ({ makeName = '', modelName = '', year: squishStyleYear }) =>
              make &&
              make.toLowerCase() === makeName.toLowerCase() &&
              model &&
              model.toLowerCase() === modelName.toLowerCase() &&
              year === `${squishStyleYear}`
          )
        : true;

      return {
        isSquishVinValid,
        squishStyles,
        isCheckDigitValid: true, // We assume this is true because partner offer eligibility will determine whether it is false.
        hasError,
        status,
      };
    },
  },
  {
    path: 'vin["{vin}"].make["{make}"].model["{model}"].year["{year}"].vinDecoderStyles',
    async resolve({ vin, make, model, year }, context) {
      const stylesResponse = await context.resolveValue(
        buildStylesBasicPathFromVin({ vin, isVinDecoderUsed: true }),
        VehicleVinModel
      );
      const { results, hasError, status } = stylesResponse ?? {};
      const squishStyles = results?.length
        ? results.map(({ styleId, year: styleYear, makeName, makeNiceId, modelName, modelNiceId }) => ({
            id: styleId,
            year: styleYear,
            makeName,
            makeNiceId,
            modelName,
            modelNiceId,
          }))
        : [];

      const isSquishVinValid = squishStyles.length
        ? squishStyles.some(
            ({ makeName = '', modelName = '', year: squishStyleYear }) =>
              make &&
              make.toLowerCase() === makeName.toLowerCase() &&
              model &&
              model.toLowerCase() === modelName.toLowerCase() &&
              year === `${squishStyleYear}`
          )
        : true;
      return {
        isSquishVinValid,
        squishStyles,
        isCheckDigitValid: true, // We assume this is true because partner offer eligibility will determine whether it is false.
        hasError,
        status,
      };
    },
  },
  {
    path: partnerOfferVehicleInfoByVinPath.segment,
    async resolve({ vin }, context) {
      let dataByVin;

      try {
        dataByVin = await context.resolveValue(searchByVinPath.build(vin), MultiOfferModel);
      } catch {
        dataByVin = {};
      }

      if (!isEmpty(dataByVin)) {
        const { vehicle, kmxConditionQuestions, kmx, carwiser } = dataByVin;

        return getVehicleInfoFromModsData({
          vehicle,
          kmxConditionQuestions,
          carwiserConditionQuestions: get(carwiser, 'conditionQuestions', []),
          offerFinalizedAt: kmx.offersFinalizedAt,
          seller: dataByVin.seller,
        });
      }

      return {
        source: 'match_not_found',
      };
    },
  },
  {
    path: partnerOfferVehicleInfoByModsIdPath.segment,
    async resolve({ modsId }, context) {
      let modsRecord;

      try {
        modsRecord = await context.resolveValue(modsRecordPath.build(modsId), MultiOfferModel);
      } catch {
        modsRecord = {};
      }

      if (!isEmpty(modsRecord)) {
        const { vehicle, conditionDetails } = modsRecord;

        const kmxConditionQuestions = JSON.parse(get(conditionDetails, 'kmx.rawData', '[]'));
        const carwiserConditionQuestions = JSON.parse(get(conditionDetails, 'carwiser.rawData', '[]'));
        const offerFinalizedAt = get(modsRecord, 'partners.kmx.offersFinalizedAt');

        return getVehicleInfoFromModsData({
          vehicle,
          kmxConditionQuestions,
          carwiserConditionQuestions,
          offerFinalizedAt,
          seller: modsRecord.seller,
        });
      }

      // Fallback for when MODS record is empty
      const vin = await context.resolveValue('estimatedAppraisalVin', VehicleModel);

      if (vin) {
        return context.resolveValue(partnerOfferVehicleInfoByVinPath.build({ vin }), PartnerOfferModel);
      }

      return {
        source: 'match_not_found',
      };
    },
  },
  {
    path: 'partnerOfferPrerequisites',
    async resolve(_, context) {
      // The following data should exist before, but in an event where it doesn't exist (or has a race condition) we will try to make the call
      const { styleId } = await context.resolveValue('allTmvParams', VehicleModel);
      // In the event if "allTmvParams" returns nothing, we should let the following error bubble up because it should be available!

      const [style, { tmvconditions: tmvConditions }, vehicleFeatures] = await Promise.all([
        context.resolveValue(`styles.${styleId}`, VehicleModel),
        context.resolveValue(buildUsedTmvPricingWithOptionsPath(styleId), VehicleModel),
        context.resolveValue(buildOptionWithOemCodeStylePath(styleId), VehicleModel),
      ]);

      const { makeName, modelName, year } = style;
      const vin = await context.resolveValue(PARTNER_OFFER_PARAMETERS_PATH.VIN, PartnerOfferModel);

      const vinEligibilityProps = { context, vin, make: makeName, model: modelName, year };
      clearVinEligibilityCache(vinEligibilityProps);

      return {
        tmvConditions,
        vehicleFeatures,
        responseId: uuidv4(),
      };
    },
  },
  {
    path: 'offerParameters', // See PARTNER_OFFER_PARAMETERS_PATH const for available paths
  },
  {
    path:
      'offerCode["{offerCode}"].appointments.storeId["{storeId}"].startTime["{startTime}"].endTime["{endTime}"].slots',
    async resolve({ storeId, startTime, endTime }, context) {
      let response;
      const fallbackResponse = [];

      try {
        fireGetAppointmentSlotsTracking(`store_${storeId}`);
        response = await withMetrics(EdmundsPartnerOfferAPI, context).fetchJson(
          `/v2/appointments/slots?storeId=${storeId}&startTime=${startTime}&endTime=${endTime}`,
          { headers: VENOM_X_PRODUCT_ID_HEADER }
        );
        if (response.length) {
          fireReceiveAppointmentSlotsTracking('available_appointments_found');
        }
        if (isEmpty(response)) {
          response = fallbackResponse;
        }
      } catch (e) {
        response = fallbackResponse;
        fireReceiveAppointmentSlotsTracking(`${e.status}_ERROR`);
      }

      return response;
    },
  },
  {
    /**
     * The endpoint consist of both Experian and IHS calls that is done in the API level.
     *
     * Sample of the partner API call:
     * /api/partner-offers/vins/search-by-plate
     */
    path: 'vin.licensePlate["{licensePlate}"].stateCode["{stateCode}"]',
    async resolve({ licensePlate, stateCode }, context) {
      let response;
      const createdDateUtc = getSyncDate();
      const quotebackId = uuidv5(`${stateCode}|${createdDateUtc}`, EPO_UUIDV5_NAMESPACE);
      try {
        response = await withMetrics(EdmundsPartnerOfferAPI, context).fetchJson('/vins/search-by-plate', {
          headers: { ...VENOM_X_PRODUCT_ID_HEADER, 'content-type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({
            plateNumber: licensePlate,
            plateState: stateCode,
            quotebackId,
            createdDateUtc,
          }),
        });
        const { vins, vin, message, service } = response;
        // VIN === '' is valid case, we should show 2 types of error messages for the user, when API failed and when VIN is ''.
        if (!vins && !vin && vin !== '') {
          throw new Error(message || 'No VIN returned for licensePlate and stateCode');
        }
        let finalVins = vins;
        if (!vin && isEmpty(vins)) {
          finalVins = [''];
        }
        response = {
          vin: isEmpty(finalVins) ? vin : finalVins[0],
          service,
          vins,
        };
      } catch (e) {
        await context.updateValue('licensePlateError', true);
        response = undefined;
      }

      return response;
    },
  },
  {
    path: 'licensePlateError',
  },
  {
    path: 'conditionsQuestions',
    async resolve(match, context) {
      let response;
      const fallbackResponse = [];
      try {
        // Bypassing Gateway API for ALL condition questions requests to avoid double-caching, per EMO-1224
        response = await withMetrics(EdmundsPartnerOfferAPI, context).fetchJson('/v2/conditions/questions');
        if (isEmpty(response)) {
          response = fallbackResponse;
        }
      } catch (e) {
        response = fallbackResponse;
      }
      return response;
    },
  },
  {
    path: 'conditionsQuestions.e2e',
    async resolve(match, context) {
      let response;
      const fallbackResponse = [];
      try {
        response = await withMetrics(EdmundsPartnerOfferAPI, context).fetchJson('/v2/mock/conditions/questions');
        if (isEmpty(response)) {
          response = fallbackResponse;
        }
      } catch (e) {
        response = fallbackResponse;
      }
      return response;
    },
  },
  {
    path: transformedConditionsQuestionsPath.segment,
    async resolve(match, context) {
      const location = await context.resolveValue('location', PageModel);

      const questions = await context.resolveValue(buildPartnerOffersConditionsQuestionsPath(location));
      return transformQuestions(fillInPartner(questions, PARTNERS.CARMAX));
    },
  },
]);

export const PartnerOfferEntities = {
  Eligibility: PropTypes.shape({
    store: PropTypes.shape({
      name: PropTypes.string,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      longitude: PropTypes.number,
      latitude: PropTypes.number,
    }),
    isEligible: PropTypes.bool,
    nearestStores: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  VinEligibility: PropTypes.shape({
    isSquishVinValid: PropTypes.bool,
    squishStyles: VehicleVinEntities.SquishStyles,
  }),
  ConditionsQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      desc: PropTypes.string,
      category: PropTypes.string,
      answerType: PropTypes.string,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          desc: PropTypes.string,
          answerType: PropTypes.string,
          detailQuestions: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              desc: PropTypes.string,
              answerType: PropTypes.string,
              answers: PropTypes.arrayOf(PropTypes.shape({})),
            })
          ),
        })
      ),
    })
  ),
};
